import { Route, Redirect } from 'react-router-dom'
import { getAccessToken } from '../services/auth/userLocalStorage';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
        <>
            {(
                <Route
                    {...rest}
                    render = {props => {
                        if(!getAccessToken()){
                            return <Redirect to='/' />
                        }
                        return <Component {...props} />
                    }}
                />
            )}
        </>
    )
}

export default ProtectedRoute
